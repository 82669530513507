<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-7">Sipariş Değişim Kontrol Penceresi</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <div class="order">
            <CCard style=" box-shadow: 0px 0px 1px 1px lightgray;">
              <CCardHeader>
                <strong>Sipariş Değişim No</strong>
              </CCardHeader>
              <CCardBody style="padding: 1rem ;">
                <CRow>
                  <CCol>
                    <CInput
                        type="input"
                        horizontal
                        placeholder="Sipariş no"
                        id="orderChangeId"
                        v-model="orderChangeId"
                        v-on:keyup.enter="getOrderChangeCartItems(orderChangeId)"
                      />
                      <CButton type="submit" color="success" @click="getOrderChangeCartItems(orderChangeId)">Sorgula</CButton>
                  </CCol>
                </CRow>  
              </CCardBody>
            </CCard>
            <CCard v-if="true" style=" box-shadow: 0px 0px 1px 1px lightgray;">
                <CCardHeader>
                  <strong> Sipariş Değişim İçeriği </strong>
                </CCardHeader>
                  <CCardBody style="padding: 1rem ;">
                        <CRow>
                          <CCol col="9">
                            <small><strong style="color: dodgerblue;">{{ checkedMessage }}</strong></small>
                            <small><strong style="color: green;">{{ allCheckedMessage }}</strong></small>
                            <CInput
                              style="margin-top: 10px"
                              horizontal
                              id="barcodeNumber"
                              placeholder="Barkod numarası"
                              v-model="barcodeNo"
                              v-on:keyup.enter="approveOrder(barcodeNo)"
                            />
                          </CCol>
                          <CCol>
                            <img v-if="showEttnImg" style="width: 60px; height: 60px" src="../../assets/img/thereisno-ettn-icon.png"><br/>
                            <img v-if="showStreetImg" style="width: 60px; height: 60px" src="../../assets/img/street-icon.png"><br/>
                          </CCol>
                        </CRow>
                  </CCardBody>
            </CCard>
          </div>

          <CDataTable
            :items="orderChangeCartItems"
            :items-per-page="10"
            :fields="fields"
            clickable-rows
            v-on:refresh="getOrderChangeCartItems(orderChangeId)"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CButton
                  color="info"
                  square
                  size="sm"
                  @click="approveOrder(item.barcode)"
                >
                  Onayla
                </CButton>
              </td>
            </template>
            <template #check="{item}">
              <td>
                {{ item.check ? item.check : 0 }}
              </td>
            </template>
            <template #url="{item}">
              <td><img :src="item.url"></td>
            </template>
            <template #description="{item}">
              <td>
                <span v-html="item.description"></span>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>

    <CModal
        :show="shippingLoading"
        :centered="true"
        title="Kargo Bilgilendirmesi"
        size="sm"
    >
        <div class="flexCenter">
            <p> Kargo bilgilendirme işlemi devam ediyor lütfen pencereyi kapatmayınız... </p> 
            <CSpinner  color="primary"/>
        </div>
      
        <template #footer>
            <span>
            </span>
        </template>
    </CModal>
    
    <ConfirmModal
      v-if="form == 'confirm'"
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="confirmModalEvent"
      :confirm="confirmModalEvent"
      :desc="modalDesc"
      :noFooter="true"
    />
  </CRow>
</template>
<script>
import ConfirmModal from '../components/confirmModal'
export default {
  name: 'OrderChangeCheck',
  components:{ ConfirmModal },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'confirm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      fields: [
        {key: 'url', label: 'Resim', _style: 'font-size:12px'},
        {key: 'description', label: 'Detay', _style: 'font-size:12px'},
        {key: 'skuCode', label: 'Ürün Kodu', _style: 'font-size:12px'},
        {key: 'barcode', label: 'Barkod', _style: 'font-size:12px'},
        {key: 'amount', label: 'Adet', _style: 'font-size:12px'},
        {key: 'check', label: 'Kontrol', _style: 'font-size:12px'},
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        }
      ],
      lastItem: {},
      orderChangeId: '',
      invoiceId: '',
      ordersData: {
        orders: [],
        count: 0
      },
      barcodeList: [],
      checkedResponse: {checked: '', user: '', date: ''},
      checkedMessage: '',
      allCheckedMessage: '',
      showEttnImg: false,
      showStreetImg: false,
      barcodeNo: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    shippingLoading(){
      return this.$store.getters.informShippingLoading;
    },
    orderChangeCartItems: function(){
      this.barcodeList = [];
      this.$store.getters.orderChangeCartItems.map(item => {
        this.showEttnImg = item.shippingAddress !== '192';
        this.showStreetImg = item.collectionName === 'Street';
        this.barcodeList.push(item.barcode);
      })
      return this.$store.getters.orderChangeCartItems
    }
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    async confirmModalEvent(){
      document.getElementById("barcodeNumber").readOnly = false;
      document.getElementById("orderChangeId").readOnly = false;
      this.openModalEvent()
    },
    async getOrderChangeCartItems(orderChangeId) {
      this.checkedMessage = '';
      this.allCheckedMessage = '';
      this.giftCardMessage = '';
      if (orderChangeId) {
        await this.$store.dispatch('orderChange_cartItems', {orderChangeId: orderChangeId.indexOf("C") !== -1 ? orderChangeId.substr(1, orderChangeId.length - 1) : orderChangeId})
        if(this.$store.getters.orderChangeCartItems.length < 1){
          document.getElementById("barcodeNumber").readOnly = true;
          document.getElementById("orderChangeId").readOnly = true;
          document.getElementById("orderChangeId").blur();
          this.openModalEvent('', 'confirm', null, 'Uyarı', 'Hatalı Sipariş No Girişi Yapıldı. Tekrar Deneyin')
        }else{
          document.getElementById("barcodeNumber").focus();
          await this.$store.dispatch('orderChange_isItemChecked', {orderChangeId: orderChangeId.indexOf("C") !== -1 ? orderChangeId.substr(1, orderChangeId.length - 1) : orderChangeId})
          
          if(this.$store.getters.orderCheckItemChecked.checked === 'true'){
            let msg = this.orderChangeId + ' No.lu sipariş ' + this.$store.getters.orderCheckItemChecked.date + ' tarihinde ' + 
            this.$store.getters.orderCheckItemChecked.user + ' tarafından kontrol edilmiştir.';
            document.getElementById("barcodeNumber").readOnly = true;
            document.getElementById("orderChangeId").readOnly = true;
            document.getElementById("barcodeNumber").blur();
            this.openModalEvent('', 'confirm', null, 'Bilgilendirme', msg)
            this.checkedMessage = msg
          }
        }
      }
    },
    async approveOrder(item) {
      document.getElementById("barcodeNumber").focus();
      this.barcodeNo = '';
      let index = -1;

      this.lastItem = this.orderChangeCartItems.find((e, i) => {
        if(e.barcode == item){
          index = i;
          return e;
        }
      })

      if (!this.barcodeList.includes(item) || !this.lastItem) {
        document.getElementById("barcodeNumber").readOnly = true;
        document.getElementById("orderChangeId").readOnly = true;
        document.getElementById("barcodeNumber").blur();
        this.openModalEvent('', 'confirm', null, 'Uyarı', item +" barkod numaralı ürün listede bulunmamaktadır. Lütfen bir daha kontrol ediniz.")
      }else{

        if (this.lastItem.amount !== this.lastItem.check) {
          this.$store.commit('orderChangeAddChecked',index)
        } else  {
          document.getElementById("barcodeNumber").readOnly = true;
          document.getElementById("orderChangeId").readOnly = true;
          document.getElementById("barcodeNumber").blur();
          this.openModalEvent('', 'confirm', null, 'Hatalı Giriş!', this.lastItem.description +' ürünü 1 kereden fazla okutulmuştur. Lütfen ürün adetini yeniden kontrol ediniz')
        }

        this.allBarcodesChecked = this.orderChangeCartItems.every(function (e) {
          return e.check === e.amount;
        });

        if (this.allBarcodesChecked) {
            let params = {
              "invoiceId": this.lastItem.invoiceId,
              "orderChangeId": this.orderChangeId.indexOf("C") !== -1 ? this.orderChangeId.substr(1, this.orderChangeId.length - 1) : this.orderChangeId
            };
            await this.$store.dispatch('orderChange_itemsChecked', params)
            if(this.$store.getters.orderStatus.success) {
              // DHL, UPS veya UPS(Domestic), Packup veya hepsiJet ise bildirme
              if(!['4', '6', '8'].includes(this.lastItem.shippingCompany)) {
                let data = {
                  orderChangeId: params.orderChangeId
                }
                await this.$store.dispatch('orderchange_informShippingCompany', data)

                let toast;

                if(this.$store.getters.orderStatus.success) {
                  toast = {
                    msg: 'Kargo bilgilendirmesi başarıyla gerçekleşmiştir.',
                    header: 'Başarılı',
                    color: 'success'
                  }
                }else {
                  toast = {
                    msg: 'Kargo bilgilendirme aşamasında bir hata oluştu' + this.$store.getters.orderStatus.errMsg,
                    header: 'Bilgilendirme Hata',
                    color: 'danger'
                  }
                }

                this.$store.commit('showToast', toast)
              }
            }
            this.allCheckedMessage = 'Kontrol İşlemi Tamamlanmıştır.';
            this.orderChangeId = '';
            this.checkedMessage = '';
            this.barcodeNo = ''
            document.getElementById("orderChangeId").focus();
        }

      }

    },
  },
}
</script>
<style scoped>
.order{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.order > div{
  min-width: 40%;
}
</style>